<template>
  <section class="recent-purchases-section">
    <h2>Peças Adquiridas <span class="highlight">Recentemente:</span></h2>
    <p class="description">
      Garantimos sua satisfação! Se por qualquer motivo você não estiver de
      acordo com nossa oferta, basta recusá-la, e devolveremos seus itens sem
      nenhum custo adicional. Sem complicações, sem questionamentos.
    </p>

    <swiper
      :loop="true"
      :looped-slides="4"
      space-between="10"
      navigation
      :breakpoints="breakpoints"
      class="carousel-container"
    >
      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda1.jpg" alt="Imagem de 1.31g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (327.5).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">1.31g</p>
        </div>
      </swiper-slide>

      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda2.jpg" alt="Imagem de 9.48g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (1896.0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">9.48g</p>
        </div>
      </swiper-slide>

      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda3.jpg" alt="Imagem de 11.82g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (2364.0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">11.82g</p>
        </div>
      </swiper-slide>

      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda4.jpg" alt="Imagem de 14.2g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (2840.0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">14.2g</p>
        </div>
      </swiper-slide>

      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda5.jpg" alt="Imagem de 16.5g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (3300.0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">16.5g</p>
        </div>
      </swiper-slide>

      <swiper-slide class="carousel-item">
        <img src="@/assets/compras/venda6.jpg" alt="Imagem de 18.11g" />
        <div class="item-details">
          <p class="item-price">Valor pago</p>
          <h3>
            {{
              (3622.0).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
            }}
          </h3>
          <p class="item-weight">18.11g</p>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    }
  },
}
</script>

<style scoped>
.recent-purchases-section {
  padding: 40px 20px;
  background-color: #fff;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  font-family: 'Manrope', sans-serif;
  font-size: 36px;
  margin-bottom: 20px;
}

.highlight {
  color: #e1b026;
}

.description {
  font-family: 'Manrope', sans-serif;
  color: #000000;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 680px;
}

.swiper {
  max-width: 1220px;
}

.carousel-item {
  background-color: #f1f1f1;
  border: 1px solid #e6e6e6;
  padding: 25px;
  border-radius: 10px;
  max-width: 270px;
  text-align: center;
}

.carousel-item img {
  width: 270px;
  height: 285px;
  margin-bottom: 15px;
  border-radius: 5px;

  object-fit: cover;
  object-position: 0 -60px;
}

.item-details {
  text-align: left;
}

.item-price {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #262520;
  margin: 0;
}

.item-weight {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  color: #262520;
  margin: 0;
}

h3 {
  font-family: 'Manrope', sans-serif;
  font-size: 36px;
  font-weight: normal;
  color: #e4b122;
  margin: 0;
}

@media (max-width: 768px) {
  .carousel-container {
    max-width: 360px;
  }

  .recent-purchases-section {
    width: 100%;
  }

  .carousel-item {
    max-width: 170px;

    img {
      width: 140px;
      height: 180px;
      object-fit: cover;
    }
  }

  .item-details {
    h3 {
      font-size: 22.67px;
      padding: 10px 0;
    }

    .item-price,
    .item-weight {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .carousel-container {
    max-width: 100%;
  }

  .recent-purchases-section {
    h2 {
      font-family: Manrope;
      font-size: 28px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }

    p {
      font-family: Manrope;
      font-size: 17px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    .carousel-item {
      max-width: 120px;
      padding: 15px;

      img {
        width: 120px;
        height: 150px;
        object-position: center;
      }

      .item-details {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
          padding: 0;
        }
      }
    }
  }
}
</style>
