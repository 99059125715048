<template>
  <div class="hero">
    <img alt="Logo Ouro Fácil" src="@/assets/logoouro.png" class="brand-logo" />

    <h1>
      VENDA SEU OURO DE FORMA SIMPLES E SEGURA -
      <span> PAGAMENTO RÁPIDO E GARANTIDO!</span>
    </h1>

    <p>
      Solicite frete grátis e receba o pagamento em 24 horas! Uma transação
      privada que é segura, sem riscos e fácil de fazer.
    </p>

    <div class="video"><h1>video</h1></div>
  </div>
</template>

<script>
export default {
  name: 'SiteHero',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>

<style scoped>
.hero {
  background-image: url('@/assets/bg1.png');
  color: var(--background-color);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;

  min-height: 70vh;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h1 {
    max-width: 780px;
  }
}

.video {
  width: 100%;
  max-width: 750px;
  height: 420px;
  background-color: #3d3227;
  border: 1px solid #695a4b;

  margin-bottom: 64px;
}

.brand-logo {
  max-width: 100%;
  object-fit: contain;
  max-width: 180px;
}

span {
  color: var(--primary-color);
  font-family: 'Manrope', sans-serif;
}

p {
  max-width: 680px;
  font-size: 20px;
  margin-top: 0;
}

@media only screen and (max-width: 720px) {
  .hero {
    width: 100%;
    padding: 30px;
  }

  h1 {
    font-size: 20px;
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    margin-top: 0;
  }

  .video {
    max-width: 370px;
    max-height: 208px;
  }
}
</style>
