<template>
  <div class="form-container" id="form-content">
    <form @submit.prevent="submitForm">
      <h2>Como você deseja enviar seus itens?</h2>
      <div class="checkbox">
        <div class="form-group">
          <div class="custom-radio">
            <input
              type="radio"
              id="sedex"
              value="sedex"
              v-model="formData.envio"
            />
            <label for="sedex">
              <span class="checkbox-icon"></span>
            </label>
          </div>
          <span>Etiqueta SEDEX (mais rápida)</span>
        </div>

        <div class="form-group">
          <div class="custom-radio">
            <input
              type="radio"
              id="pickup-point"
              value="pickup-point"
              v-model="formData.envio"
            />
            <label for="pickup-point">
              <span class="checkbox-icon"></span>
            </label>
          </div>
          <span>Entregar em um ponto de coleta autorizado.</span>
        </div>
      </div>

      <div class="form-content">
        <div class="form-group">
          <input
            type="text"
            v-model="formData.name"
            name="name"
            placeholder="Insira seu nome completo"
            required
          />
        </div>

        <div class="form-group">
          <input
            type="email"
            v-model="formData.email"
            name="email"
            placeholder="Insira seu melhor e-mail"
            required
          />
        </div>

        <div class="form-group">
          <input
            type="tel"
            v-model="formData.phone"
            name="phone"
            placeholder="Insira seu telefone"
            required
          />
        </div>

        <div class="form-group">
          <input
            type="text"
            v-model="formData.city"
            name="city"
            placeholder="Insira sua cidade"
            required
          />
        </div>

        <!-- Aqui é o campo de produto que será atualizado automaticamente -->
        <div class="form-group">
          <input
            type="text"
            v-model="formData.product"
            name="product"
            :placeholder="productPlaceholder"
            required
          />
        </div>

        <div class="form-group">
          <button type="submit">SOLICITAR ORÇAMENTO GRATUITO</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'FormularioComponent',
  props: {
    product: {
      type: String,
      default: '', // Valor inicial vazio, será preenchido pela calculadora
    },
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        phone: '',
        city: '',
        product: '', // Será atualizado automaticamente com os dados da calculadora
        envio: 'sedex', // Define SEDEX como padrão
      },
    }
  },
  computed: {
    productPlaceholder() {
      // Exibe o placeholder "O que você deseja vender?" até que um produto seja selecionado na calculadora
      return this.product ? this.product : 'O que você deseja vender?'
    },
  },
  watch: {
    product(newProduct) {
      // Atualiza o campo de produto automaticamente quando houver dados da calculadora
      if (newProduct) {
        this.formData.product = newProduct
      }
    },
  },
  methods: {
    submitForm() {
      const whatsappNumber = '5537999479108' // Seu número de WhatsApp no formato internacional
      const envioTipo =
        this.formData.envio === 'sedex'
          ? 'Etiqueta SEDEX'
          : 'Ponto de coleta autorizado'

      const message = `Olá, meu nome é ${this.formData.name}, meu e-mail é ${this.formData.email}, meu telefone é ${this.formData.phone}, moro em ${this.formData.city}, e estou interessado em vender o seguinte produto: ${this.formData.product}. Método de envio escolhido: ${envioTipo}.`

      const encodedMessage = encodeURIComponent(message)
      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`

      // Redireciona o usuário para o WhatsApp
      window.open(whatsappUrl, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
/* Mantendo o estilo original, incluindo as alterações necessárias para radio buttons */
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    margin-top: -64px;
    border-radius: 20px;
    padding: 45px;
    background-color: #e9e9e9;
    max-width: 750px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

h2 {
  font-family: 'Manrope', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
  color: #000000;
}

.checkbox {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.custom-radio {
  position: relative;
  display: inline-block;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .checkbox-icon {
    width: 18px;
    height: 18px;
    background-color: #ffcd3c;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-image: url('@/assets/icons/check.svg');
      background-size: cover;
      transform: translate(-50%, -50%) scale(0);
      transition: transform 0.2s ease;
    }
  }

  input[type='radio']:checked + label .checkbox-icon::after {
    transform: translate(-50%, -50%) scale(1);
  }
}

.form-content {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .form-group {
    flex: 1 1 48%;
    display: flex;
    flex-direction: column;
  }

  input {
    max-width: 100%;
    padding: 15px;
    border-radius: 5px;
    font-family: 'Manrope', sans-serif;
    border: 1px solid #ccc;
  }
}

button {
  background-color: #e1b026;
  color: white;
  border: none;
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

button:hover {
  background-color: #fba022;
}

input[type='radio'] {
  margin-right: 8px;
}

@media only screen and (max-width: 720px) {
  .form-container {
    form {
      padding: 30px;
      margin-top: -42px;
    }
  }

  button {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }
}

@media only screen and (max-width: 480px) {
  .form-container {
    padding: 0 30px;

    form {
      max-width: 80%;
      padding: 20px;

      h2 {
        font-family: Manrope;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
      }

      span {
        font-family: Manrope;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
    }

    .form-content {
      button {
        font-family: Manrope;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        padding: 16px 0;
      }
    }
  }
}
</style>
