<template>
  <div class="container">
    <SiteHeroDefault />
    <FormularioComponent :product="product" />
    <PrimeirosCards />
    <CardsEmpresas />
    <hr class="custom-line" />
    <PhysicalStoreSection />

    <section class="middle-banner">
      <div class="middle-banner-text">
        <p>
          A forma mais <span>simples e segura</span> de vender ouro, prata e
          diamantes:
        </p>

        <hr class="custom-middle-line" />
      </div>

      <img
        alt="Logo Ouro Fácil"
        src="@/assets/default-middle-banner.png"
        class="banner-girl"
      />
      <img
        alt="Logo Ouro Fácil"
        src="@/assets/default-middle-banner-mobile.png"
        class="banner-girl-mobile"
      />
    </section>
    <CalculatorSection @updateProduct="updateProduct" />
    <section class="middle-video">
      <div class="texts">
        <p>CONHEÇA NOSSO PROCESSO SEGURO E TRANSPARENTE DE COMPRA DE OURO.</p>

        <hr class="custom-middle-line" />

        <p class="white-text">
          Assista ao vídeo e entenda cada etapa da avaliação e pagamento,
          garantindo confiança e tranquilidade.
        </p>
      </div>

      <div class="video">
        <iframe
          width="780"
          height="450"
          src="https://www.youtube.com/embed/3JZ_D3ELwOQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <TrustSection />

    <RecentPurchases />
    <TestimonialsSection />
    <SatisfactionGuarantee />
    <CaixaSection />
    <FaqSection />
    <FooterSection />

    <button @click="openWhatsApp" class="whatsapp-button">
      <img src="@/assets/whatsapp.svg" alt="WhatsApp" />
    </button>
  </div>
</template>

<script>
import SiteHeroDefault from "./SiteHeroDefault.vue"
import FormularioComponent from "./FormularioComponent.vue"
import PrimeirosCards from "./PrimeirosCards.vue"
import CardsEmpresas from "./CardsEmpresas.vue"
import TrustSection from "./TrustSection.vue"
import CalculatorSection from "./CalculatorSection.vue"
import RecentPurchases from "./RecentPurchases.vue"
import TestimonialsSection from "./TestimonialsSection.vue"
import SatisfactionGuarantee from "./SatisfactionGuarantee.vue"
import PhysicalStoreSection from "./PhysicalStoreSection.vue"
import FaqSection from "./FaqSection.vue"
import { scrollToMixin } from "@/scrollToMixin"
import CaixaSection from "./CaixaSection.vue"
import FooterSection from "./FooterSection.vue"

export default {
  name: "HomeDefault",
  components: {
    SiteHeroDefault,
    PrimeirosCards,
    FormularioComponent,
    CardsEmpresas,
    TrustSection,
    CalculatorSection,
    RecentPurchases,
    TestimonialsSection,
    SatisfactionGuarantee,
    PhysicalStoreSection,
    FaqSection,
    CaixaSection,
    FooterSection,
  },
  mixins: [scrollToMixin],
  data() {
    return {
      product: "",
    }
  },
  methods: {
    updateProduct(productInfo) {
      // Atualiza o valor do produto com base no evento emitido pelo CalculatorSection
      this.product = productInfo
      console.log("Product updated:", this.product)
    },
    openWhatsApp() {
      const phoneNumber = "+5537991117815"
      const url = `https://wa.me/${phoneNumber}`
      window.open(url, "_blank")
    },
  },
}
</script>

<style scoped>
.middle-video {
  background: #f3c441;
  width: 100vw;
  padding: 108px 80px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 60px;

  .video {
    width: 780px;
    height: 450px;
  }

  .texts {
    max-width: 725px;
    margin-left: 85px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    p {
      font-family: Manrope;
      font-size: 46px;
      font-weight: 600;
      line-height: 52px;
      text-align: left;
      color: #000000;
      margin-top: 0;
    }

    .custom-middle-line {
      border: 5px solid #000000;
      width: 336px;
      height: 0px;
      top: 4816px;
      left: 551px;
      gap: 0px;
      border: 5px 0px 0px 0px;
      angle: -0.17 deg;
      margin-left: 0;
    }

    .white-text {
      font-family: Manrope;
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
      color: #ffffff;
      max-width: 680px;
      padding-top: 32px;
    }
  }
}

@media only screen and (max-width: 1367px) {
  .middle-video {
    justify-content: flex-start;
    max-height: 325px;

    .video {
      max-width: 300px;

      iframe {
        width: 560px;
        height: 315px;
      }
    }

    .texts {
      max-width: 615px;
      p {
        font-size: 32px;
      }

      .white-text {
        font-size: 24px;
      }
    }
  }
}

.middle-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  max-height: 450px;

  img {
    width: 100vw;
    object-fit: cover;
    height: 415px;
  }

  .middle-banner-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 415px;
    min-width: 50%;

    background: #1a1a1a;
    padding: 0 30px 0px 30px;

    p {
      font-family: Manrope;
      font-size: 46px;
      font-weight: 600;
      line-height: 52px;
      text-align: left;
      color: #fff;
      max-width: 510px;
    }

    span {
      color: #f3c441;
    }

    .custom-middle-line {
      border: 5px solid #f3c441;
      width: 85px;
      height: 0px;
      top: 3985px;
      left: 173px;
      gap: 0px;
      border: 5px 5px 0px 0px;
      opacity: 0px;
      border-radius: 50px;
    }
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button img {
  width: 30px;
  height: 30px;
}

.custom-line {
  border: none;
  height: 1px;
  background-color: #9c9c9c;
  width: 1140px;
  margin-top: 40px;
}

.container {
  width: 100%;
  max-width: 100vw;
  padding: 0 24px;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner-girl {
  width: 100vw;
  object-fit: contain;
}

.banner-girl-mobile {
  display: none;
}

@media only screen and (max-width: 720px) {
  .middle-video {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    max-height: 55vh;

    .video {
      max-width: 100%;
      height: 200px;

      iframe {
        width: 360px;
        height: 200px;
      }
    }

    .texts {
      max-width: 615px;
      margin: 0;
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-size: 32px;
        text-align: center;
        font-family: Manrope;
        line-height: 32px;
        font-weight: bold;
        padding: 0 30px;
      }

      .custom-middle-line {
        margin-left: auto;
        width: 160px;
        border-radius: 50px;
      }

      .white-text {
        font-size: 24px;
        text-align: center;
        line-height: 32px;
        max-width: 360px;
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }

  .middle-banner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 100vw;
    margin-bottom: 40px;

    img {
      width: 100vw;
      object-fit: contain;
    }

    .middle-banner-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: #1a1a1a;
      padding: 0 30px 60px 30px;

      p {
        font-family: Manrope;
        font-size: 30px;
        font-weight: 600;
        line-height: 32px;
        color: #fff;
        text-align: center;
        width: 100%;
      }

      span {
        color: #f3c441;
      }

      .custom-middle-line {
        border: 5px solid #f3c441;
        width: 85px;
        height: 0px;
        top: 3985px;
        left: 173px;
        gap: 0px;
        border: 5px 5px 0px 0px;
        opacity: 0px;
      }
    }
  }

  .custom-line {
    max-width: 100%;
  }

  .banner-girl {
    display: none;
  }

  .banner-girl-mobile {
    display: block;
  }

  .cta-button {
    width: 100%;

    button {
      width: 100%;
      padding: 20px 50px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 480px) {
  .cta-button button {
    padding: 20px 0;
    font-size: 16px;
    margin-top: -30px;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .middle-banner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    max-width: 100vw;

    img {
      margin-top: 120px;
      width: 100vw;
      object-fit: contain;
    }

    .middle-banner-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background: #1a1a1a;
      padding: 0 30px 60px 30px;

      p {
        font-family: Manrope;
        font-size: 30px;
        font-weight: 600;
        line-height: 32px;
        color: #fff;
        text-align: center;
        width: 100%;
      }

      span {
        color: #f3c441;
      }

      .custom-middle-line {
        border: 5px solid #f3c441;
        width: 85px;
        height: 0px;
        top: 3985px;
        left: 173px;
        gap: 0px;
        border: 5px 5px 0px 0px;
        opacity: 0px;
      }
    }
  }
}
@media only screen and (max-width: 376px) {
  .middle-video {
    max-height: 65vh;
    margin-top: 60px;

    .texts {
      p {
        font-size: 30px;
      }
    }
  }
}
</style>
